import { ChartConfigItem, TileChartConfigItem } from "../../types/ChartConfig";
import { camelCase } from "lodash";
import { ReportTemplateUnit, GetModuleMetricsQuery } from "../../../../graphql";
import { ReportInResponse } from "../../utils/generateChartQuery";
import { ChartDataMetric } from "./ChartDataContext";

export function hasAggregatedReport(
  config: ChartConfigItem | TileChartConfigItem,
): boolean {
  const reports = config.reports();

  if (typeof reports.filter?.reportTemplateCode === "string") {
    return reports.filter.reportTemplateCode.includes(":");
  }

  if (Array.isArray(reports.filter?.reportTemplateCode)) {
    const codes = reports.filter?.reportTemplateCode as unknown[];
    return Boolean(codes.find((x) => typeof x === "string" && x.includes(":")));
  }

  return false;
}

export function getReportUnit(
  report: ReportInResponse | undefined,
  moduleMetrics: GetModuleMetricsQuery | undefined,
): ReportTemplateUnit | string | undefined {
  const isAggregateReport = Boolean(report?.reportTemplateCode?.includes(":"));

  return isAggregateReport
    ? (getMetric(report, moduleMetrics)?.metadata?.unit ?? undefined)
    : (report?.reportTemplate.metadata?.unit ?? undefined);
}

export function getAggregatedMetric(
  report: ReportInResponse | undefined,
  moduleMetrics: GetModuleMetricsQuery | undefined,
): ChartDataMetric | undefined {
  const isAggregateReport = Boolean(report?.reportTemplateCode?.includes(":"));
  if (!isAggregateReport) return;

  return getMetric(report, moduleMetrics);
}

function getMetric(
  report: ReportInResponse | undefined,
  moduleMetrics: GetModuleMetricsQuery | undefined,
): ChartDataMetric | undefined {
  const aggregateName = report?.reportTemplateCode?.slice(
    report.reportTemplateCode.indexOf(":") + 1,
  );
  const aggregate =
    aggregateName &&
    report?.reportTemplate?.aggregatesByCode?.[aggregateName]?.aggregate;

  const metricCode = aggregate && Object.values(aggregate)[0];
  const formattedMetricCode =
    typeof metricCode === "string" ? camelCase(metricCode) : undefined;

  const datasource = moduleMetrics?.getModule?.datasources?.find(
    (x) => x.datasourceCode === report?.reportTemplate.datasourceCodeEnum,
  );

  const metric = formattedMetricCode
    ? datasource?.metrics?.find((x) => x.code === formattedMetricCode)
    : undefined;

  return metric
    ? { ...metric, aggregate: Object.keys(aggregate)[0] }
    : undefined;
}
