/* eslint-disable fp/no-this */
import { Typography, useDateFormatter, useTranslation } from "@lumar/shared";
import { HighchartsChart } from "../components/HighchartsChart";
import { ChartConfigItemBase } from "../types/ChartConfigItemBase";
import {
  ChartDataMetric,
  useChartDataContext,
} from "../components/chart-components/ChartDataContext";
import { Theme, useTheme } from "@material-ui/core";
import { sortBy } from "lodash";
import { useChartReportColorGetter } from "../chart-colors/useChartReportColors";
import { useReportTemplateUnitFormatter } from "../../locale/format-api-enum/useReportTemplateUnitFormatter";
import { renderToString } from "react-dom/server";
import { useTooltipStyles } from "../utils/useTooltipStyles";
import { Point } from "highcharts";
import {
  CrawlContextCrawlSetting,
  useCrawlContextData,
} from "../../../crawl-overview/CrawlContext";

export type ChartConfigSmallColumn = ChartConfigItemBase & {
  /**
   * Controls the number of columns visible in the chart.
   * Default value is 7.
   */
  columnCount?: number | null;
};

export function SmallColumnChart({
  columnCount: columnCountParam,
}: ChartConfigSmallColumn): JSX.Element {
  const { reports, getReportUnit, getAggregatedMetric } = useChartDataContext();
  const { crawlSetting } = useCrawlContextData();
  const tooltipClasses = useTooltipStyles();
  const theme = useTheme();
  const formatUnit = useReportTemplateUnitFormatter();
  const getReportColor = useChartReportColorGetter();
  const { t } = useTranslation("charts");

  const formatDate = useDateFormatter();

  const columnCount = columnCountParam === null ? 0 : (columnCountParam ?? 7);

  const report = reports[0];
  const series = (
    sortBy(report?.reportTrend || [], (x) =>
      x.createdAt ? new Date(x.createdAt).getTime() : 0,
    ) || []
  ).slice(-columnCount);

  const metric = getAggregatedMetric(report);
  const metricName = (() => {
    switch (metric?.aggregate) {
      case "$avg":
        return t("aggregates.avg", { metricName: metric.name });
      case "$min":
        return t("aggregates.min", { metricName: metric.name });
      case "$max":
        return t("aggregates.max", { metricName: metric.name });
      case "$sum":
        return t("aggregates.sum", { metricName: metric.name });
    }
  })();
  const thresholds = getThresholds({ metric, crawlSetting, theme });

  const values = series.map((x) => x.basic ?? 0);
  const lastValue = values[values.length - 1];

  const maxValue = Math.max(...values);
  const max = thresholds?.find((x) => x.value > maxValue)?.value ?? maxValue;

  function getThresholdColor(value: number): string | undefined {
    if (!thresholds?.length) return getReportColor(report?.reportTemplateCode);

    const idx = thresholds.reduce(
      (result, treshold, idx) => (value > treshold.value ? idx : result),
      0,
    );
    return thresholds[idx].color;
  }

  const paddedSeries = [
    ...series,
    ...Array(Math.max(0, columnCount - series.length)).fill({
      isPlaceholder: true,
    }),
  ];

  const options: Highcharts.Options = {
    chart: {
      spacing: [5, 8, 5, 8],
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      min: 0,
      max,
      endOnTick: false,
    },
    series: [
      {
        type: "column",
        name: "column-background",
        data: paddedSeries.map((value, idx) => ({
          x: idx,
          y: value.isPlaceholder ? 0 : max,
          color: value.isPlaceholder ? theme.palette.grey[300] : "#EBEFF3",
        })),
        enableMouseTracking: false,
      },
      {
        type: "column",
        data: paddedSeries.map((value, idx) => ({
          x: idx,
          y: value.basic,
          color: getThresholdColor(value.basic),
          createdAt: value.createdAt,
        })),
      },
    ],
    plotOptions: {
      column: {
        stacking: "normal",
        pointPadding: 0.05,
        borderWidth: 0,
        borderRadius: 0,
      },
    },
    tooltip: {
      useHTML: true,
      outside: true,
      shape: "rect",
      padding: 0,
      borderRadius: 0,
      borderWidth: 0,
      shadow: false,
      shared: true,
      style: {
        fontSize: "1em",
        lineHeight: "1.5em",
        color: theme.palette.text.primary,
        // Making sure that sidebar next to the chart
        // doesn't cut the tooltip off.
        zIndex: theme.zIndex.drawer + 1,
      },
      formatter: function () {
        const value = this.y ?? 0;
        const createdAt = getCreatedAt(this.point);

        return renderToString(
          <div className={tooltipClasses.container}>
            <div className={tooltipClasses.textContainer}>
              <div
                className={tooltipClasses.urlCountText}
                style={{ color: getThresholdColor(value) }}
              >
                {formatUnit(value, getReportUnit(report))}
              </div>
              {createdAt && (
                <div>
                  {formatDate(createdAt, {
                    dateStyle: "long",
                    timeStyle: "short",
                  })}
                </div>
              )}
              <div>{metricName}</div>
            </div>
          </div>,
        );
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
  };

  return (
    <div>
      <Typography
        style={{
          display: "block",
          lineHeight: theme.typography.pxToRem(20),
          fontSize: theme.typography.pxToRem(24),
          fontWeight: 600,
          margin: "6px 6px 9px 14px",
          color: getThresholdColor(lastValue),
        }}
      >
        {formatUnit(lastValue, getReportUnit(report))}
      </Typography>
      <div style={{ height: 90 }}>
        <HighchartsChart options={options} />
      </div>
    </div>
  );
}

function getThresholds({
  metric,
  crawlSetting,
  theme,
}: {
  metric: ChartDataMetric | undefined;
  crawlSetting: CrawlContextCrawlSetting | undefined;
  theme: Theme;
}): { value: number; color: string }[] | undefined {
  const siteSpeedScoring = metric?.metadata?.siteSpeedScoring;
  if (!siteSpeedScoring) return;

  const isMobile = Boolean(
    crawlSetting?.userAgentString
      ? crawlSetting?.userAgentIsMobile
      : crawlSetting?.userAgent?.isMobile,
  );

  return metric?.metadata?.siteSpeedScoring
    ? [
        { value: 0, color: theme.palette.green[500] },
        {
          value: isMobile
            ? siteSpeedScoring.mobile.p10
            : siteSpeedScoring.desktop.p10,
          color: theme.palette.yellow[400],
        },
        {
          value: isMobile
            ? siteSpeedScoring.mobile.median
            : siteSpeedScoring.desktop.median,
          color: theme.palette.red[500],
        },
      ]
    : undefined;
}

function getCreatedAt(point: Point): Date | undefined {
  const createdAt = (point as unknown as Record<string, unknown>)?.createdAt;
  if (typeof createdAt !== "string") return;

  const result = new Date(createdAt);
  return isNaN(result.getTime()) ? undefined : result;
}
